import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Tutor from "./pages/tutor";

function App() {
  return (
    <html className="min-h-screen bg-gray-100">
      <body className="min-h-screen">
        <div className="wrapper">
          <BrowserRouter>
            <Routes>
              <Route path="/tutor/*" element={<Tutor />} />
            </Routes>
          </BrowserRouter>
        </div>
      </body>
    </html>
  );
}

export default App;
