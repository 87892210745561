import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

class AuthService {
  tutorLogin(email, password) {
    return axios
      .post(API_URL + "tutor/auth/login", {
        email,
        password,
      })
      .then((response) => {
        console.log(response);
        if (response.data.token) {
          localStorage.setItem("token", response.data.token);
        }
      });
  }

  logout() {
    localStorage.removeItem("token");
  }

  register(name, email, password) {
    return axios.post(API_URL + "tutor/auth/register", {
      name,
      email,
      password,
    });
  }

  getCurrentUser() {
    return localStorage.getItem("token");
  }
}

export default new AuthService();
