import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

function authHeader() {
  const token = localStorage.getItem("token");

  return { Authorization: "Bearer " + token };
}

class TuteeService {
  getPupils() {
    return axios.get(API_URL + "tutor/pupils", { headers: authHeader() });
  }
}

export default new TuteeService();
