import React from "react";

export interface FormProps {
  children: React.ReactNode;
  onSubmit(event: React.FormEvent): void;
}

export const Form = ({ children, onSubmit }: FormProps) => {
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(e);
  };

  return (
    <div>
      <form className="w-full space-y-6">
        {children}

        <div className="flex justify-end">
          <button
            type="submit"
            onClick={handleSubmit}
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Book
          </button>
        </div>
      </form>
    </div>
  );
};
