import { useEffect } from "react";
import HorizontalCardList from "./horizontalCardList";
import Card from "./card";
import LessonCard from "./LessonCard";
import Button from "./Button";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { DateTime } from "luxon";
import { fetchLessons } from "../features/lessons/lessonsSlice";

export default function TutorLessonList() {
  let lessons = useAppSelector((state) => state.lessons.lessons);
  lessons = lessons
    .filter((lesson) => lesson.end_date.diffNow().as("weeks") < 2)
    .slice(0, 5);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      fetchLessons({
        startDate: DateTime.now(),
        endDate: DateTime.now().plus({ weeks: 2 }),
      })
    );
  }, [dispatch]);

  return (
    <HorizontalCardList>
      {lessons.length === 0 && (
        <Link to={"/tutor/book"}>
          <Card title={"No Upcoming Lessons"}>
            <Button content="Book Lesson Now" />
          </Card>
        </Link>
      )}
      {lessons.map((lesson) => (
        <Link to={`/tutor/lessons/${lesson.id}`} key={lesson.id}>
          <LessonCard
            lessonTitle={lesson.title}
            startTime={DateTime.fromISO(lesson.start_date)}
            endTime={DateTime.fromISO(lesson.end_date)}
            id={lesson.id}
          />
        </Link>
      ))}
    </HorizontalCardList>
  );
}
