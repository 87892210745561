import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Feed from "./feed";
import Login from "./login";
import { useEffect, useState } from "react";
import Register from "./register";
import TutorLayout from "../../layouts/tutor";
import authService from "../../services/auth.service";
import Book from "./book.tsx";
import Calendar from "./calendar";
import Lesson from "./lesson.tsx";

function App() {
  const [token] = useState(null);
  let location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    let token = authService.getCurrentUser();
    console.log("token: " + token);

    if (
      token === null &&
      (location.pathname !== "/tutor/login" ||
        location.pathname !== "/tutor/register")
    ) {
      navigate("/tutor/login");
    }
  }, [token, location.pathname, navigate]);

  return (
    <div className="wrapper">
      <Routes>
        <Route element={<TutorLayout />}>
          <Route path="/feed" element={<Feed title="Dashboard" />} />
          <Route path="/book" element={<Book title="Book Lesson" />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/lessons/:lessonId" element={<Lesson />} />
        </Route>

        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
      </Routes>
    </div>
  );
}

export default App;
