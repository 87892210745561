import React from "react";

export interface FormSectionProps {
  children: React.ReactNode;
  title: string;
  description: string;
}

export default function FormSection({
  children,
  title,
  description,
}: FormSectionProps) {
  return (
    <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {title}
          </h3>
          <p className="mt-1 text-sm text-gray-500">{description}</p>
        </div>
        <div className="mt-5 space-y-6 md:col-span-2 md:mt-0">{children}</div>
      </div>
    </div>
  );
}
