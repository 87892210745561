export default function Card({ children, title }) {
  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">{title}</h3>
        {/*<p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details and application.</p>*/}
      </div>
      <div className="border-t border-gray-200 p-4">{children}</div>
    </div>
  );
}
