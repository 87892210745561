import Card from "./card";
import Button from "./Button";
import { lessonService } from "../services/lesson.service";

export default function LessonCard({ lessonTitle, startTime, endTime, id }) {
  const handleJoinLessonButton = async (event) => {
    event.preventDefault();
    const resp = await lessonService.joinLesson(id);
    console.log(resp.data.url);
    window.open(resp.data.url, "_blank").focus();
  };

  return (
    <Card title={lessonTitle}>
      <div className="container">
        <p className="text-sm text-black-50">
          starting in{" "}
          {startTime
            .diffNow()
            .shiftTo("minutes")
            .rescale()
            .normalize()
            .toHuman()}
        </p>
        <p className="text-sm text-black-50">
          {/*lasting {endTimeMoment.from(startTimeMoment, true)}*/}
        </p>
        <Button onClick={handleJoinLessonButton} content="Join" />
      </div>
    </Card>
  );
}
