import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { lessonService } from "../../services/lesson.service";
import { Section } from "../../components/Layout/VerticalDetail/Section";
import { CheckIcon, HandThumbUpIcon } from "@heroicons/react/20/solid";
import { classNames } from "../../utils/classes";
import { DateTime } from "luxon";
import { Button, ButtonType } from "../../components/Elements/Button";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  cancelLesson,
  fetchLesson,
  selectLesson,
} from "../../features/lessons/lessonsSlice";

const eventTypes = {
  created: { icon: CheckIcon, bgColorClass: "bg-green-500" },
  advanced: { icon: HandThumbUpIcon, bgColorClass: "bg-blue-500" },
  completed: { icon: CheckIcon, bgColorClass: "bg-green-500" },
};

const timeline = [
  {
    id: 1,
    type: eventTypes.created,
    content: "Lesson Created",
    target: "",
    date: "Sep 20",
    datetime: "2020-09-20",
  },
];

export default function Lesson() {
  const { lessonId } = useParams();

  // const [lesson, setLesson] = useState(undefined as LessonResponse | undefined)
  const lessons = useAppSelector((state) => state.lessons);
  const lesson = selectLesson(lessons, lessonId ?? "");

  const dispatch = useAppDispatch();

  const handleJoinLessonButton = async (event: any) => {
    event.preventDefault();
    const resp = await lessonService.joinLesson(lessonId ?? "");
    console.log(resp.data.url);
    window.open(resp.data.url, "_blank")?.focus();
  };

  useEffect(() => {
    dispatch(fetchLesson(lessonId ?? ""));
  }, [lessonId, dispatch]);

  // TODO: Come back to make time update without many API calls.
  // useEffect(() => {
  //   const interval = setInterval(() => getLesson(), 1000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);
  //
  // useEffect(() => {
  //   // React advises to declare the async function directly inside useEffect
  //
  //   // You need to restrict it at some point
  //   // This is just dummy code and should be replaced by actual
  //   if (!lesson) {
  //     getLesson();
  //   }
  // }, [lessonId]);

  if (lesson === undefined) {
    return <div></div>;
  }

  if (lessons.lessonLoading) {
    return <div>Loading...</div>;
  }

  return (
    <main>
      {/* Page header */}
      <div className="mx-auto max-w-3xl px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
        <div className="flex items-center space-x-5">
          <div className="flex-shrink-0">
            <div className="relative">
              <img
                className="h-16 w-16 rounded-full"
                src="https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                alt=""
              />
              <span
                className="absolute inset-0 rounded-full shadow-inner"
                aria-hidden="true"
              />
            </div>
          </div>
          <div>
            <h1 className="text-2xl font-bold text-gray-900">{lesson.title}</h1>
            <p className="text-sm font-medium text-gray-500">
              Lesson with only Katie.
            </p>
          </div>
        </div>
        <div className="justify-stretch mt-6 flex flex-col-reverse space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-y-0 sm:space-x-3 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
          <Button
            onClick={(_event: any) => {
              dispatch(cancelLesson(lesson.id.toString()));
            }}
            type={ButtonType.SecondaryAction}
          >
            Cancel Lesson
          </Button>
          <Button onClick={handleJoinLessonButton}>Join Classroom</Button>
        </div>
      </div>

      <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div className="space-y-6 lg:col-span-2 lg:col-start-1">
          <Section title="Lesson Details">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  {DateTime.fromISO(lesson.start_date).diffNow().toMillis() >
                    0 && "Starts In"}
                  {DateTime.fromISO(lesson.start_date).diffNow().toMillis() <
                    0 && "Started"}
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {DateTime.fromISO(lesson.start_date)
                    .diffNow()
                    .rescale()
                    .shiftTo("days", "hours", "minutes")
                    .mapUnits((x, u) =>
                      u === "minutes" ? Math.ceil(Math.abs(x)) : Math.abs(x)
                    )
                    .toHuman({ listStyle: "long" })}{" "}
                  {DateTime.fromISO(lesson.start_date).diffNow().toMillis() <
                    0 && "ago"}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Email address
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {lesson.is_cancelled && "Cancelled"}
                  {!lesson.is_cancelled && "Not Cancelled"}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Salary expectation
                </dt>
                <dd className="mt-1 text-sm text-gray-900">$120,000</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Phone</dt>
                <dd className="mt-1 text-sm text-gray-900">+1 555-555-5555</dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">About</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {lesson.notes || "No notes"}
                </dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">Resources</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
                    {/*{attachments.map((attachment) => (*/}
                    {/*  <li*/}
                    {/*    key={attachment.name}*/}
                    {/*    className="flex items-center justify-between py-3 pl-3 pr-4 text-sm"*/}
                    {/*  >*/}
                    {/*    <div className="flex w-0 flex-1 items-center">*/}
                    {/*      <PaperClipIcon*/}
                    {/*        className="h-5 w-5 flex-shrink-0 text-gray-400"*/}
                    {/*        aria-hidden="true"*/}
                    {/*      />*/}
                    {/*      <span className="ml-2 w-0 flex-1 truncate">*/}
                    {/*        {attachment.name}*/}
                    {/*      </span>*/}
                    {/*    </div>*/}
                    {/*    <div className="ml-4 flex-shrink-0">*/}
                    {/*      <a*/}
                    {/*        href={attachment.href}*/}
                    {/*        className="font-medium text-blue-600 hover:text-blue-500"*/}
                    {/*      >*/}
                    {/*        Download*/}
                    {/*      </a>*/}
                    {/*    </div>*/}
                    {/*  </li>*/}
                    {/*))}*/}
                  </ul>
                </dd>
              </div>
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">Slides</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
                    {/*{attachments.map((attachment) => (*/}
                    {/*  <li*/}
                    {/*    key={attachment.name}*/}
                    {/*    className="flex items-center justify-between py-3 pl-3 pr-4 text-sm"*/}
                    {/*  >*/}
                    {/*    <div className="flex w-0 flex-1 items-center">*/}
                    {/*      <PaperClipIcon*/}
                    {/*        className="h-5 w-5 flex-shrink-0 text-gray-400"*/}
                    {/*        aria-hidden="true"*/}
                    {/*      />*/}
                    {/*      <span className="ml-2 w-0 flex-1 truncate">*/}
                    {/*        {attachment.name}*/}
                    {/*      </span>*/}
                    {/*    </div>*/}
                    {/*    <div className="ml-4 flex-shrink-0">*/}
                    {/*      <a*/}
                    {/*        href={attachment.href}*/}
                    {/*        className="font-medium text-blue-600 hover:text-blue-500"*/}
                    {/*      >*/}
                    {/*        Download*/}
                    {/*      </a>*/}
                    {/*    </div>*/}
                    {/*  </li>*/}
                    {/*))}*/}
                  </ul>
                </dd>
              </div>
            </dl>
          </Section>

          <Section
            title="Recordings"
            subtitle="Available recordings for this lesson"
          >
            <></>
          </Section>
        </div>

        <section
          aria-labelledby="timeline-title"
          className="lg:col-span-1 lg:col-start-3"
        >
          <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
            <h2
              id="timeline-title"
              className="text-lg font-medium text-gray-900"
            >
              Timeline
            </h2>

            {/* Activity Feed */}
            <div className="mt-6 flow-root">
              <ul className="-mb-8">
                {timeline.map((item, itemIdx) => (
                  <li key={item.id}>
                    <div className="relative pb-8">
                      {itemIdx !== timeline.length - 1 ? (
                        <span
                          className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                          aria-hidden="true"
                        />
                      ) : null}
                      <div className="relative flex space-x-3">
                        <div>
                          <span
                            className={classNames(
                              item.type.bgColorClass,
                              "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                            )}
                          >
                            <item.type.icon
                              className="h-5 w-5 text-white"
                              aria-hidden="true"
                            />
                          </span>
                        </div>
                        <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                          <div>
                            <p className="text-sm text-gray-500">
                              {item.content}{" "}
                              <a href="/" className="font-medium text-gray-900">
                                {item.target}
                              </a>
                            </p>
                          </div>
                          <div className="whitespace-nowrap text-right text-sm text-gray-500">
                            <time dateTime={item.datetime}>{item.date}</time>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}
