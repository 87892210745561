import Button from "./Button";
import React, { useState } from "react";

export interface MultiFileUploadProps {
  onChange(filenames: File[]): void;
}

export default function MultiFileUpload({ onChange }: MultiFileUploadProps) {
  const [uploadedFiles, setUploadedFiles] = useState([] as File[]);
  const [fileLimit, setFileLimit] = useState(false);

  const maximumFileLimit = 10;

  const inputElement = React.createRef<HTMLInputElement>();

  const updateFileList = (files: File[]) => {
    setUploadedFiles(files);
    onChange(files);
  };

  const handleFileEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files === null) {
      return;
    }

    const chosenFiles = Array.from(event.target.files).slice();
    handleUploadFiles(chosenFiles);
  };

  const handleUploadFiles = (files: File[]) => {
    const uploaded = [...uploadedFiles];

    let limitExceeded = false;

    files.some((file) => {
      if (uploaded.length === maximumFileLimit) setFileLimit(true);
      if (uploaded.length > maximumFileLimit) {
        setFileLimit(false);
        limitExceeded = true;
        return true;
      }

      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
      }

      return true;
    });

    if (!limitExceeded) updateFileList(uploaded);
  };

  const handleRemoveFile = (
    event: React.MouseEvent<HTMLButtonElement>,
    file: File
  ) => {
    updateFileList(
      uploadedFiles.filter(function (item) {
        return item !== file;
      })
    );

    if (uploadedFiles.length < maximumFileLimit) {
      setFileLimit(false);
    }

    event.preventDefault();
  };

  return (
    <div className="grid grid-cols-9 gap-4">
      <div className="col-span-3">
        <Button
          content={"Select File"}
          onClick={(
            event: React.MouseEvent<HTMLButtonElement>,
            _context: any
          ) => {
            inputElement?.current?.click();
            event.preventDefault();
          }}
        />
        <input
          ref={inputElement}
          type="file"
          style={{ display: "none" }}
          multiple
          accept="application/pdf application/vnd.ms-powerpoint application/vnd.openxmlformats-officedocument.presentationml.presentation application/vnd.openxmlformats-officedocument.presentationml.template application/vnd.openxmlformats-officedocument.presentationml.slideshow"
          onChange={handleFileEvent}
          disabled={fileLimit}
        />
      </div>
      <div className="col-span-6">
        <div className="block pl-4 pr-4 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
          {uploadedFiles.length === 0 && (
            <div className="pt-4 pb-4">No Files Selected</div>
          )}
          {uploadedFiles.map((file, i) => (
            <div
              className={
                (i + 1 !== uploadedFiles.length ? "border-b " : "") +
                "pt-4 pb-4 grid grid-cols-9 gap-4 border-gray-300"
              }
            >
              <div className="col-span-7 h-full flex items-center">
                <span className="text-center">{file.name}</span>
              </div>
              <div className="col-span-2">
                <Button
                  content="Remove"
                  onClick={handleRemoveFile}
                  context={file}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
