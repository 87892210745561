import React from "react";

export enum DOW {
  Monday = "Mon",
  Tuesday = "Tue",
  Wednesday = "Wed",
  Thursday = "Thu",
  Friday = "Fri",
  Saturday = "Sat",
  Sunday = "Sun",
}

export interface DayOfWeekPickerProps {
  values: Map<DOW, boolean>;
  onChange(values: Map<DOW, boolean>): void;
}

export function CreateDaysOfWeekMap(): Map<DOW, boolean> {
  return new Map([
    [DOW.Monday, false],
    [DOW.Tuesday, false],
    [DOW.Wednesday, false],
    [DOW.Thursday, false],
    [DOW.Friday, false],
    [DOW.Saturday, false],
    [DOW.Sunday, false],
  ]);
}

export function DayOfWeekPicker({ values, onChange }: DayOfWeekPickerProps) {
  const createCallbackFunction = (
    dow: DOW
  ): ((event: React.ChangeEvent<HTMLInputElement>) => void) => {
    return (event: React.ChangeEvent<HTMLInputElement>): void => {
      let newValues = new Map(values);

      newValues.set(dow, event.target.checked);

      onChange(newValues);
    };
  };

  return (
    <div className="md:grid md:grid-cols-7">
      {[
        DOW.Monday,
        DOW.Tuesday,
        DOW.Wednesday,
        DOW.Thursday,
        DOW.Friday,
        DOW.Saturday,
        DOW.Sunday,
      ].map((day) => (
        <div>
          <label
            htmlFor="comments"
            className="block text-sm font-medium text-gray-700"
          >
            {day}
          </label>
          <input
            id="comments"
            aria-describedby="comments-description"
            name="comments"
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
            onChange={createCallbackFunction(day)}
            checked={values.get(day)}
          />
        </div>
      ))}
    </div>
  );
}
