import React from "react";

export interface FieldWithLabelProps {
  children: React.ReactNode;
  label: string;
}

export function FieldWithLabel({ children, label }: FieldWithLabelProps) {
  return (
    <div>
      <label
        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
        htmlFor="grid-first-name"
      >
        {label}
      </label>

      {children}
    </div>
  );
}
