import React from "react";

export function Column({
  children,
  columns = 2,
}: {
  children: React.ReactNode;
  columns: number;
}) {
  return (
    <div className={`md:grid md:grid-cols-${columns} md:gap-6`}>{children}</div>
  );
}
