import React, { useEffect, useState } from "react";
import tuteeService from "../../services/tutee.service";
import Select from "react-select";
import MultiFileUpload from "../../components/MultiFileUpload";
import { lessonService, PresignedFileUrl } from "../../services/lesson.service";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-date-picker";
import { Form } from "../../components/Forms/Layout/Form";
import {
  PupilListResponse,
  PupilResponse,
} from "@tutoroom/common/types/api/pupils";
import FormSection from "../../components/Forms/Layout/FormSection";
import { FieldWithLabel } from "../../components/Forms/Layout/FieldWithLabel";
import { Toggle } from "../../components/Forms/Fields/Toggle";
import { Column } from "../../components/Forms/Layout/Column";
import {
  CreateDaysOfWeekMap,
  DayOfWeekPicker,
  DOW,
} from "../../components/Forms/Fields/DayOfWeekPicker";
import { DateTime, Duration } from "luxon";

export default function Book() {
  const [pupils, setPupils] = useState([] as PupilResponse[]);
  const [slides, setSlides] = useState([] as File[]);
  const [resources, setResources] = useState([] as File[]);

  const [notes, setNotes] = useState("");
  const [title, setTitle] = useState("");

  const [startDate, setStartDate] = useState(undefined as undefined | DateTime);
  const [endDate, setEndDate] = useState(undefined as undefined | DateTime);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [recurrenceDays, setRecurrenceDays] = useState(CreateDaysOfWeekMap());

  const navigate = useNavigate();

  const [recurring, setRecurring] = useState(false);
  const [ends, setEnds] = useState(false);

  useEffect(() => {
    tuteeService.getPupils().then((response: PupilListResponse) => {
      console.log(response.data);
      setPupils(response.data);
    });
  }, [setPupils]);

  const handleSlideFileUpdate = (files: File[]) => {
    setSlides(files);
  };

  const handleResourcesFileUpdate = (files: File[]) => {
    setResources(files);
  };

  const uploadFiles = async (event: React.FormEvent) => {
    let slideIds: Array<string> = await Promise.all(
      slides.map(async (file) => {
        const response = await lessonService.getPresignedFileUrl();

        const xhr = new XMLHttpRequest();

        let responseData: PresignedFileUrl = response.data as PresignedFileUrl;

        xhr.open("PUT", responseData.url, true);

        //xhr.setRequestHeader('Content-Type', 'image/jpeg');
        xhr.onload = () => {
          if (xhr.status === 200) {
            console.log("Uploaded data successfully");
          }
        };
        xhr.onerror = () => {
          console.log("Nope");
        };

        xhr.send(file);

        return responseData.id;
      })
    );

    let resourceIds: Array<string> = await Promise.all(
      resources.map(async (file) => {
        const response = await lessonService.getPresignedFileUrl();

        const xhr = new XMLHttpRequest();

        let responseData: PresignedFileUrl = response.data as PresignedFileUrl;

        xhr.open("PUT", responseData.url, true);

        //xhr.setRequestHeader('Content-Type', 'image/jpeg');
        xhr.onload = () => {
          if (xhr.status === 200) {
            console.log("Uploaded data successfully");
          }
        };
        xhr.onerror = () => {
          console.log("Nope");
        };

        xhr.send(file);

        return responseData.id;
      })
    );

    const startTimeDuration = Duration.fromObject({
      hours: parseInt(startTime.split(":")[0]),
      minute: parseInt(startTime.split(":")[1]),
    });

    const endTimeDuration = Duration.fromObject({
      hours: parseInt(endTime.split(":")[0]),
      minute: parseInt(endTime.split(":")[1]),
    });

    if (startDate === undefined || endDate === undefined) {
      // TODO Throw and handle an error here.
      return;
    }

    const lessonStartTime = startDate.plus(startTimeDuration);
    const lessonEndTime = startDate.plus(endTimeDuration);

    await lessonService.createLesson({
      notes: notes,
      resources: resourceIds,
      scheduled_start: lessonStartTime.toISO(),
      scheduled_end: lessonEndTime.toISO(),
      duration: lessonStartTime.until(lessonEndTime).toDuration().as("minutes"),
      recurring: recurring,
      every_n_weeks: 1,
      weekdays: {
        monday: recurrenceDays.get(DOW.Monday) ?? false,
        tuesday: recurrenceDays.get(DOW.Tuesday) ?? false,
        wednesday: recurrenceDays.get(DOW.Wednesday) ?? false,
        thursday: recurrenceDays.get(DOW.Thursday) ?? false,
        friday: recurrenceDays.get(DOW.Friday) ?? false,
        saturday: recurrenceDays.get(DOW.Saturday) ?? false,
        sunday: recurrenceDays.get(DOW.Sunday) ?? false,
      },
      slides: slideIds,
      title: title,
    });
  };

  const handleBook = async (event: React.FormEvent) => {
    await uploadFiles(event);

    navigate("/tutor/feed");
  };

  const options = pupils.map((pupil: { id: number; name: string }) => {
    return { label: pupil.name, value: pupil.id };
  });

  return (
    <Form onSubmit={handleBook}>
      <FormSection
        title="Lesson Information"
        description="Some basic information about the lesson to be scheduled. A title, a short description, and which students will be attending."
      >
        <FieldWithLabel label="Lesson Title">
          <input
            className="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
            id="grid-first-name"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            type="text"
            placeholder="Transport systems in multicellular organisms"
          />
        </FieldWithLabel>

        <FieldWithLabel label="Lesson Notes">
          <textarea
            id="message"
            rows={8}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            className="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Here you can put any notes/reminders for things you want to cover in the lesson..."
          />
        </FieldWithLabel>

        <FieldWithLabel label="Students">
          <Select options={options} isMulti={true} />
        </FieldWithLabel>
      </FormSection>

      <FormSection
        title="Scheduling"
        description="Schedule this lesson as either a one-off lesson, or configure when this lesson will repeat."
      >
        <FieldWithLabel label="Recurring">
          <Toggle value={recurring} onChange={setRecurring} />
        </FieldWithLabel>

        {recurring && (
          <>
            <FieldWithLabel label="Start Date">
              <DatePicker
                className="block p-2.5 w-full bg-white text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                minDate={new Date()}
                value={startDate?.toJSDate()}
                onChange={(date: Date) => {
                  setStartDate(DateTime.fromJSDate(date));
                }}
              />
            </FieldWithLabel>

            <Column columns={2}>
              <FieldWithLabel label="Start Time">
                <input
                  className="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  type="time"
                  onChange={(e) => {
                    setStartTime(e.target.value);
                    console.log(e.target.value);
                  }}
                  value={startTime}
                />
              </FieldWithLabel>

              <FieldWithLabel label="End Time">
                <input
                  className="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  type="time"
                  onChange={(e) => {
                    setEndTime(e.target.value);
                    console.log(e.target.value);
                  }}
                  value={endTime}
                />
              </FieldWithLabel>
            </Column>

            <Column columns={2}>
              <FieldWithLabel label="Ends">
                <Toggle value={ends} onChange={setEnds} />
              </FieldWithLabel>

              {ends && (
                <FieldWithLabel label="End Date">
                  <DatePicker
                    className="block p-2.5 w-full bg-white text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    minDate={new Date()}
                    value={endDate?.toJSDate()}
                    onChange={(date: Date) => {
                      setEndDate(DateTime.fromJSDate(date));
                    }}
                  />
                </FieldWithLabel>
              )}
            </Column>

            <FieldWithLabel label="Repeat Days">
              <DayOfWeekPicker
                values={recurrenceDays}
                onChange={setRecurrenceDays}
              />
            </FieldWithLabel>
          </>
        )}

        {!recurring && (
          <>
            <FieldWithLabel label="Lesson Date">
              <DatePicker
                className="block p-2.5 w-full bg-white text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                minDate={new Date()}
              />
            </FieldWithLabel>

            <Column columns={2}>
              <FieldWithLabel label="Start Time">
                <input
                  className="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  type="time"
                  onChange={(e) => {
                    setStartTime(e.target.value);
                    console.log(e.target.value);
                  }}
                  value={startTime}
                />
              </FieldWithLabel>

              <FieldWithLabel label="End Time">
                <input
                  className="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  type="time"
                  onChange={(e) => {
                    setEndTime(e.target.value);
                    console.log(e.target.value);
                  }}
                  value={endTime}
                />
              </FieldWithLabel>
            </Column>
          </>
        )}
      </FormSection>

      <FormSection
        title="Resources"
        description="Setup lesson resources such as worksheets or slides to pre-load into the classroom."
      >
        <FieldWithLabel label="Presentation Slides">
          <MultiFileUpload onChange={handleSlideFileUpdate} />
        </FieldWithLabel>

        <FieldWithLabel label="Other Resources">
          <MultiFileUpload onChange={handleResourcesFileUpdate} />
        </FieldWithLabel>
      </FormSection>
    </Form>
  );
}
