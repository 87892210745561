import React from "react";
import { classNames } from "../../utils/classes";

export interface ButtonProps {
  children: React.ReactNode;
  type?: ButtonType;
  [x: string]: any;
}

export enum ButtonType {
  MainAction = "MainAction",
  SecondaryAction = "SecondaryAction",
}

export function Button({
  children,
  type = ButtonType.MainAction,
  ...props
}: ButtonProps) {
  return (
    <button
      {...props}
      className={classNames(
        "ml-3 inline-flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium shadow-sm focus:outline-none focus:ring-2  focus:ring-offset-2",
        type === ButtonType.MainAction &&
          "focus:ring-indigo-500 bg-indigo-600 hover:bg-indigo-700 text-white",
        type === ButtonType.SecondaryAction &&
          "text-gray-700 hover:bg-gray-50 focus:ring-blue-500 focus:ring-offset-gray-100 border-gray-300 bg-white"
      )}
    >
      {children}
    </button>
  );
}
