import { useEffect, useRef, useState } from "react";
import { DateTime } from "luxon";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { fetchLessons } from "../features/lessons/lessonsSlice";

export type CalendarEntry = {
  date: DateTime;
  isCurrent: boolean;
  isSelected: boolean;
  isToday: boolean;
  events: Event[];
};

export type Event = {
  id: string;
  href: string;
  datetime: DateTime;
  startminute: number;
  length: number;
  name: string;
};

function getTableDates(
  selectedDate: DateTime,
  dispatch: (startDate: DateTime, endDate: DateTime) => void
): CalendarEntry[] {
  const startOfWeek = selectedDate.startOf("week");
  const endOfWeek = selectedDate.endOf("week");

  let currentDate = startOfWeek;

  dispatch(startOfWeek, endOfWeek);

  let entries: CalendarEntry[] = [];

  while (currentDate <= endOfWeek) {
    entries.push({
      date: currentDate,
      isSelected: currentDate.startOf("day") === selectedDate.startOf("day"),
      isCurrent: currentDate.startOf("day") === DateTime.now().startOf("day"),
      isToday: currentDate.startOf("day") === DateTime.now().startOf("day"),
      events: [],
    });

    currentDate = currentDate.plus({ days: 1 });
  }

  return entries;
}

export interface IWeekCalendarProps {
  currentDate: DateTime;
}

export default function WeekCalendar({ currentDate }: IWeekCalendarProps) {
  const container = useRef<HTMLDivElement>(null);
  const containerNav = useRef<HTMLDivElement>(null);
  const containerOffset = useRef<HTMLDivElement>(null);

  const lessons = useAppSelector((state) => state.lessons.lessons);

  const events: Event[] = lessons.map((lesson) => {
    return {
      id: lesson.id.toString(),
      href: lesson.id.toString(),
      datetime: DateTime.fromISO(lesson.start_date),
      name: lesson.title,
      startminute: Math.floor(
        (DateTime.fromISO(lesson.start_date).toMillis() -
          DateTime.fromISO(lesson.start_date).startOf("day").toMillis()) /
          60000 /
          5
      ),
      length: Math.ceil(
        (DateTime.fromISO(lesson.end_date).toMillis() -
          DateTime.fromISO(lesson.start_date).toMillis()) /
          60000 /
          5
      ),
    };
  });

  console.log("events: %s", events);

  const dispatch = useAppDispatch();
  const [unfurnishedDates, setDates] = useState([] as CalendarEntry[]);

  useEffect(() => {
    setDates(
      getTableDates(currentDate, (startDate: DateTime, endDate: DateTime) => {
        dispatch(fetchLessons({ startDate: startDate, endDate: endDate }));
      })
    );
  }, [currentDate, dispatch]);

  console.log(unfurnishedDates);

  const dates = unfurnishedDates.map((date) => {
    return {
      ...date,
      events: events.filter(
        // eslint-disable-next-line no-loop-func
        (event) => {
          return (
            event.datetime.startOf("day").toISODate() ===
            date.date.startOf("day").toISODate()
          );
        }
      ),
    };
  });

  console.log(dates);

  useEffect(() => {
    // Set the container scroll position based on the current time.
    const currentMinute = new Date().getHours() * 60;

    if (
      container.current === null ||
      containerNav.current === null ||
      containerOffset.current === null
    ) {
      console.log(
        "Week calendar container unexpectedly null. Couldn't auto-scroll."
      );
      return;
    }

    container.current.scrollTop =
      ((container.current.scrollHeight -
        containerNav.current.offsetHeight -
        containerOffset.current.offsetHeight) *
        currentMinute) /
      1440;
  }, []);

  return (
    <div
      ref={container}
      className="isolate flex flex-auto flex-col overflow-auto bg-white h-screen"
    >
      <div
        style={{ width: "165%" }}
        className="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full"
      >
        <div
          ref={containerNav}
          className="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-8"
        >
          <div className="grid grid-cols-7 text-sm leading-6 text-gray-500 sm:hidden">
            {dates.map((day) => (
              <button
                type="button"
                className="flex flex-col items-center pt-2 pb-3"
                key={day.date.day}
              >
                {day.date.toFormat("EEEEE")}{" "}
                <span className="mt-1 flex h-8 w-8 items-center justify-center font-semibold text-gray-900">
                  {day.date.day}
                </span>
              </button>
            ))}
          </div>

          <div className="-mr-px hidden grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500 sm:grid">
            <div className="col-end-1 w-14" />
            {dates.map((day) => (
              <div
                key={day.date.day}
                className="flex items-center justify-center py-3"
              >
                <span>
                  {day.date.toFormat("EEE")}{" "}
                  <span className="items-center justify-center font-semibold text-gray-900">
                    {day.date.day}
                  </span>
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-auto">
          <div className="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100" />
          <div className="grid flex-auto grid-cols-1 grid-rows-1">
            {/* Horizontal lines */}
            <div
              className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
              style={{ gridTemplateRows: "repeat(48, minmax(1.5rem, 1fr))" }}
            >
              <div ref={containerOffset} className="row-end-1 h-7"></div>
              <div>
                <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                  12AM
                </div>
              </div>
              <div />
              <div>
                <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                  1AM
                </div>
              </div>
              <div />
              <div>
                <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                  2AM
                </div>
              </div>
              <div />
              <div>
                <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                  3AM
                </div>
              </div>
              <div />
              <div>
                <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                  4AM
                </div>
              </div>
              <div />
              <div>
                <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                  5AM
                </div>
              </div>
              <div />
              <div>
                <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                  6AM
                </div>
              </div>
              <div />
              <div>
                <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                  7AM
                </div>
              </div>
              <div />
              <div>
                <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                  8AM
                </div>
              </div>
              <div />
              <div>
                <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                  9AM
                </div>
              </div>
              <div />
              <div>
                <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                  10AM
                </div>
              </div>
              <div />
              <div>
                <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                  11AM
                </div>
              </div>
              <div />
              <div>
                <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                  12PM
                </div>
              </div>
              <div />
              <div>
                <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                  1PM
                </div>
              </div>
              <div />
              <div>
                <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                  2PM
                </div>
              </div>
              <div />
              <div>
                <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                  3PM
                </div>
              </div>
              <div />
              <div>
                <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                  4PM
                </div>
              </div>
              <div />
              <div>
                <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                  5PM
                </div>
              </div>
              <div />
              <div>
                <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                  6PM
                </div>
              </div>
              <div />
              <div>
                <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                  7PM
                </div>
              </div>
              <div />
              <div>
                <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                  8PM
                </div>
              </div>
              <div />
              <div>
                <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                  9PM
                </div>
              </div>
              <div />
              <div>
                <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                  10PM
                </div>
              </div>
              <div />
              <div>
                <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                  11PM
                </div>
              </div>
              <div />
            </div>

            {/* Vertical lines */}
            <div className="col-start-1 col-end-2 row-start-1 hidden grid-cols-7 grid-rows-1 divide-x divide-gray-100 sm:grid sm:grid-cols-7">
              <div className="col-start-1 row-span-full" />
              <div className="col-start-2 row-span-full" />
              <div className="col-start-3 row-span-full" />
              <div className="col-start-4 row-span-full" />
              <div className="col-start-5 row-span-full" />
              <div className="col-start-6 row-span-full" />
              <div className="col-start-7 row-span-full" />
              <div className="col-start-8 row-span-full w-8" />
            </div>

            {/* Events */}
            <ol
              className="col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-8"
              style={{
                gridTemplateRows: "0.75rem repeat(288, minmax(0, 1fr)) auto",
              }}
            >
              {dates.map((date) =>
                date.events.map((event) => (
                  <li
                    className={`relative mt-px flex sm:col-start-${event.datetime.weekday}`}
                    style={{
                      gridRow: `${event.startminute} / span ${event.length}`,
                    }}
                  >
                    <a
                      href="/"
                      className="group absolute inset-1 flex flex-col overflow-y-auto rounded-lg bg-blue-50 p-1 text-xs leading-5 hover:bg-blue-100"
                    >
                      <p className="order-1 font-semibold text-blue-700">
                        {event.name}
                      </p>
                      {/*<p className="text-blue-500 group-hover:text-blue-700">*/}
                      {/*  <time dateTime="2022-01-12T06:00">*/}
                      {/*    {event.datetime.toLocaleString(DateTime.TIME_SIMPLE)}*/}
                      {/*  </time>*/}
                      {/*</p>*/}
                    </a>
                  </li>
                ))
              )}
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}
