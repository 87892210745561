import React from "react";

export interface ButtonProps {
  content: string;
  onClick(event: React.MouseEvent<HTMLButtonElement>, context?: any): void;
  context?: any;
}

export default function Button({ content, onClick, context }: ButtonProps) {
  const callback = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick(event, context);
  };

  return (
    <button
      onClick={callback}
      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
    >
      {content}
    </button>
  );
}
