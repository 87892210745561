import React, { useEffect, useState } from "react";
import tuteeService from "../../services/tutee.service";
import Card from "../../components/card";
import TutorLessonList from "../../components/TutorLessonList";

export default function Feed() {
  const [pupils, setPupils] = useState([]);

  useEffect(() => {
    tuteeService.getPupils().then((response) => {
      console.log(response.data);
      setPupils(response.data);
    });
  }, [setPupils]);

  return (
    <div>
      <TutorLessonList />
      <div className="columns-2 pb-4">
        <Card title="Feed">
          <ul>
            {pupils.map((pupil) => {
              return <li>{pupil.email}</li>;
            })}
          </ul>
        </Card>
        <Card title="Future Lessons">
          <ul>
            {pupils.map((pupil) => {
              return <li>{pupil.email}</li>;
            })}
          </ul>
        </Card>
      </div>
    </div>
  );
}
