import axios from "axios";
import { DateTime } from "luxon";
import {
  LessonResponse,
  NewLessonRequest,
} from "@tutoroom/common/types/api/lesson";

const API_URL = process.env.REACT_APP_API_URL;

function authHeader() {
  const token = localStorage.getItem("token");

  return { Authorization: "Bearer " + token };
}

export interface Lesson {
  id: number;
  title: string;
  scheduled_start: DateTime;
  scheduled_end: DateTime;
  notes: string;
  slides: string[];
  resources: string[];
}

export interface PresignedFileUrl {
  id: string;
  url: string;
}

class LessonService {
  getUpcomingLessons() {
    return axios.get(API_URL + "tutor/lessons/upcoming", {
      headers: authHeader(),
    });
  }

  getPresignedFileUrl() {
    return axios.post(
      API_URL + "tutor/files",
      { name: "Test" },
      { headers: authHeader() }
    );
  }

  async cancelLesson(lessonId: string) {
    return await axios.post(
      API_URL + `tutor/lessons/${lessonId}/cancel`,
      undefined,
      {
        headers: authHeader(),
      }
    );
  }

  createLesson(lesson: NewLessonRequest) {
    return axios.post(API_URL + "tutor/lessons", lesson, {
      headers: authHeader(),
    });
  }

  joinLesson(id: string) {
    return axios.get(API_URL + `tutor/lessons/${id}/join`, {
      headers: authHeader(),
    });
  }

  async getLessonById(id: string): Promise<LessonResponse> {
    return await axios
      .get(API_URL + `tutor/lessons/${id}`, {
        headers: authHeader(),
      })
      .then(
        (resp) =>
          ({
            ...resp.data,
            start_date: DateTime.fromISO(resp.data.start_date),
            end_date: DateTime.fromISO(resp.data.end_date),
          } as LessonResponse)
      );
  }

  async getLessonsBetweenDate(
    startDate: DateTime,
    endDate: DateTime
  ): Promise<LessonResponse[]> {
    return (
      await axios.get(API_URL + `tutor/lessons`, {
        params: {
          filter_start: startDate.toISO(),
          filter_end: endDate.toISO(),
        },
        headers: authHeader(),
      })
    ).data.map((data: LessonResponse) => {
      return {
        ...data,
        start_date: DateTime.fromISO(data.start_date),
        end_date: DateTime.fromISO(data.end_date),
      };
    });
  }
}

export const lessonService = new LessonService();
