import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { lessonService } from "../../services/lesson.service";
import { LessonResponse } from "@tutoroom/common/types/api/lesson";
import { DateTime } from "luxon";

interface LessonsState {
  lessons: LessonResponse[];
  loading: boolean;
  lessonLoading: boolean;
}

const initialState: LessonsState = {
  loading: true,
  lessonLoading: true,
  lessons: [],
};

const lessonsSlice = createSlice({
  name: "lessons",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchLessons.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchLessons.fulfilled, (state, action) => {
        state.loading = false;

        // There's no real point in removing the lessons already here as it'll just make
        // loading previously visited calendar pages take a bit to load too.
        //
        // Instead, we combine the two arrays and then get unique values by lesson id.
        state.lessons = [
          ...new Map(
            [...state.lessons, ...action.payload].map((v) => [
              `${v.id}-${v.is_scheduled}-${v.is_exception}-${v.start_date}`,
              v,
            ])
          ).values(),
        ];
      })
      .addCase(fetchLessons.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(fetchLesson.pending, (state, action) => {
        if (selectLesson(state, action.meta.arg) === undefined) {
          state.lessonLoading = true;
        }
      })
      .addCase(fetchLesson.fulfilled, (state, action) => {
        state.lessonLoading = false;
        state.lessons = [
          ...new Map(
            [...state.lessons, action.payload].map((v) => [
              `${v.id}-${v.is_scheduled}-${v.is_exception}-${v.start_date}`,
              v,
            ])
          ).values(),
        ];
      })
      .addCase(cancelLesson.fulfilled, (state, action) => {
        state.lessons = state.lessons.map((lesson) => {
          if (lesson.id === action.meta.arg) {
            return {
              ...lesson,
              is_cancelled: true,
            };
          }

          return lesson;
        });
      });
  },
});

interface FetchLessonsArguments {
  startDate: DateTime;
  endDate: DateTime;
}

export const fetchLessons = createAsyncThunk(
  "lessons/getCalendarLessons",
  async ({ startDate, endDate }: FetchLessonsArguments) => {
    return await lessonService.getLessonsBetweenDate(startDate, endDate);
  }
);

export const fetchLesson = createAsyncThunk(
  "lessons/getLesson",
  async (lessonId: string) => {
    return await lessonService.getLessonById(lessonId);
  }
);

export const cancelLesson = createAsyncThunk(
  "lessons/cancelLesson",
  async (lessonId: string) => {
    return await lessonService.cancelLesson(lessonId);
  }
);

export default lessonsSlice.reducer;

const selectLessons = (state: LessonsState) => state.lessons;
const selectLessonId = (state: LessonsState, itemId: string) => itemId;

export const selectLesson = createSelector(
  [selectLessons, selectLessonId],
  (lessons: LessonResponse[], lessonId: string) =>
    lessons.filter((lesson) => lesson.id.toString() === lessonId).pop()
);
