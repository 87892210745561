import { ClockIcon } from "@heroicons/react/20/solid";
import { DateTime } from "luxon";
import { CalendarEntry, Event } from "./Calendar";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { fetchLessons } from "../features/lessons/lessonsSlice";
import { Link } from "react-router-dom";

function getTableDates(
  selectedDate: DateTime,
  dispatch: (startDate: DateTime, endDate: DateTime) => void
): CalendarEntry[] {
  const startOfMonth = selectedDate.startOf("month");
  const endOfMonth = selectedDate.endOf("month");
  const currentMonth = startOfMonth.month;
  const startOfCalendar = startOfMonth.minus({
    days: startOfMonth.weekday - 1,
  });
  const endOfCalendar = endOfMonth.plus({ days: 7 - endOfMonth.weekday });

  dispatch(startOfCalendar, endOfCalendar);

  let currentDate = startOfCalendar;

  let entries: CalendarEntry[] = [];

  while (currentDate <= endOfCalendar) {
    entries.push({
      date: currentDate,
      isSelected: currentDate.startOf("day") === selectedDate.startOf("day"),
      isCurrent: currentDate.month === currentMonth,
      isToday: currentDate.startOf("day") === DateTime.now().startOf("day"),
      events: [],
    });

    currentDate = currentDate.plus({ days: 1 });
  }

  return entries;
}

function classNames(...classes: (string | boolean)[]): string {
  return classes.filter(Boolean).join(" ");
}

export interface IMonthCalendarProps {
  currentDate: DateTime;
}

export default function MonthCalendar({ currentDate }: IMonthCalendarProps) {
  const lessons = useAppSelector((state) => state.lessons.lessons);
  const events: Event[] = lessons.map((lesson) => {
    return {
      id: lesson.id.toString(),
      href: lesson.id.toString(),
      datetime: DateTime.fromISO(lesson.start_date),
      name: lesson.title,
    };
  });

  const dispatch = useAppDispatch();
  const [unfurnishedDates, setDates] = useState([] as CalendarEntry[]);

  useEffect(() => {
    setDates(
      getTableDates(currentDate, (startDate: DateTime, endDate: DateTime) => {
        dispatch(fetchLessons({ startDate: startDate, endDate: endDate }));
      })
    );
  }, [currentDate, dispatch]);

  console.log(lessons);

  const dates = unfurnishedDates.map((date) => {
    return {
      ...date,
      events: events.filter(
        // eslint-disable-next-line no-loop-func
        (event) => {
          return (
            event.datetime.startOf("day").toISODate() ===
            date.date.startOf("day").toISODate()
          );
        }
      ),
    };
  });

  const selectedDay = dates.find((day) => day.isSelected);

  return (
    <>
      <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
        <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
          <div className="bg-white py-2">
            M<span className="sr-only sm:not-sr-only">on</span>
          </div>
          <div className="bg-white py-2">
            T<span className="sr-only sm:not-sr-only">ue</span>
          </div>
          <div className="bg-white py-2">
            W<span className="sr-only sm:not-sr-only">ed</span>
          </div>
          <div className="bg-white py-2">
            T<span className="sr-only sm:not-sr-only">hu</span>
          </div>
          <div className="bg-white py-2">
            F<span className="sr-only sm:not-sr-only">ri</span>
          </div>
          <div className="bg-white py-2">
            S<span className="sr-only sm:not-sr-only">at</span>
          </div>
          <div className="bg-white py-2">
            S<span className="sr-only sm:not-sr-only">un</span>
          </div>
        </div>
        <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
          <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px">
            {dates.map((day) => (
              <div
                key={day.date.toISO()}
                className={classNames(
                  day.isCurrent ? "bg-white" : "bg-gray-50 text-gray-500",
                  "relative py-2 px-3"
                )}
              >
                <time
                  dateTime={day.date.toISO()}
                  className={
                    day.isToday
                      ? "flex h-6 w-6 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white"
                      : undefined
                  }
                >
                  {day.date.toISODate().split("-").pop()?.replace(/^0/, "")}
                </time>
                {day.events.length > 0 && (
                  <ol className="mt-2">
                    {day.events.slice(0, 2).map((event) => (
                      <li key={event.id}>
                        <Link
                          to={`/tutor/lessons/${event.href}`}
                          className="group flex"
                        >
                          <p className="flex-auto truncate font-medium text-gray-900 group-hover:text-indigo-600">
                            {event.name}
                          </p>
                          <time
                            dateTime={event.datetime.toISO()}
                            className="ml-3 hidden flex-none text-gray-500 group-hover:text-indigo-600 xl:block"
                          >
                            {event.datetime.toLocaleString()}
                          </time>
                        </Link>
                      </li>
                    ))}
                    {day.events.length > 2 && (
                      <li className="text-gray-500">
                        + {day.events.length - 2} more
                      </li>
                    )}
                  </ol>
                )}
              </div>
            ))}
          </div>
          <div className="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
            {dates.map((day) => (
              <button
                key={day.date.toISODate()}
                type="button"
                className={classNames(
                  day.isCurrent ? "bg-white" : "bg-gray-50",
                  (day.isSelected || day.isToday) && "font-semibold",
                  day.isSelected && "text-white",
                  !day.isSelected && day.isToday && "text-indigo-600",
                  !day.isSelected &&
                    day.isCurrent &&
                    !day.isToday &&
                    "text-gray-900",
                  !day.isSelected &&
                    !day.isCurrent &&
                    !day.isToday &&
                    "text-gray-500",
                  "flex h-14 flex-col py-2 px-3 hover:bg-gray-100 focus:z-10"
                )}
              >
                <time
                  dateTime={day.date.toISODate()}
                  className={classNames(
                    day.isSelected &&
                      "flex h-6 w-6 items-center justify-center rounded-full",
                    day.isSelected && day.isToday && "bg-indigo-600",
                    day.isSelected && !day.isToday && "bg-gray-900",
                    "ml-auto"
                  )}
                >
                  {day.date.toISODate().split("-").pop()?.replace(/^0/, "")}
                </time>
                <span className="sr-only">{day.events.length} events</span>
                {day.events.length > 0 && (
                  <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                    {day.events.map((event) => (
                      <span
                        key={event.id}
                        className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400"
                      />
                    ))}
                  </span>
                )}
              </button>
            ))}
          </div>
        </div>
      </div>
      {selectedDay && selectedDay.events && selectedDay.events.length > 0 && (
        <div className="py-10 px-4 sm:px-6 lg:hidden">
          <ol className="divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm shadow ring-1 ring-black ring-opacity-5">
            {selectedDay.events.map((event) => (
              <li
                key={event.id}
                className="group flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50"
              >
                <div className="flex-auto">
                  <p className="font-semibold text-gray-900">{event.name}</p>
                  <time
                    dateTime={event.datetime.toISO()}
                    className="mt-2 flex items-center text-gray-700"
                  >
                    <ClockIcon
                      className="mr-2 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {event.datetime.toLocaleString()}
                  </time>
                </div>
                <Link
                  to={`/tutor/lessons/${event.href}`}
                  className="ml-6 flex-none self-center rounded-md border border-gray-300 bg-white py-2 px-3 font-semibold text-gray-700 opacity-0 shadow-sm hover:bg-gray-50 focus:opacity-100 group-hover:opacity-100"
                >
                  Edit<span className="sr-only">, {event.name}</span>
                </Link>
              </li>
            ))}
          </ol>
        </div>
      )}
    </>
  );
}
